import React from "react";
import SEO from "../components/seo";
import Logo from "../images/havist-logo.svg";
import { Col } from "react-bootstrap";

export default function() {
  return (
    <React.Fragment>
      <SEO title={"Selling domains"} />
      <section>
        <Logo />
        <h2>
          Havist: The starting point. First. Number one. Second to none. Top
          dog.
        </h2>
        Is your business an Alpha or an Also-Ran? If the former, you need an
        Alpha Name for the World Wide Web! Your World Wide Web address can mean
        the difference between people finding you and not finding you... between
        recognition and non-recognition... between your customers remembering
        you and not remembering you... between an aura of sophistication and
        stability or a second-rate status... between success and failure! It is
        that important. Thus, the so-called "Domain Names Gold Rush" where
        businesses, organizations and even individuals are scrambling to secure
        Internet addresses that they hope to ride to profitability. Not long
        ago, thousands of domain names using common words were available for a
        song. Unfortunately, these are long gone. Occasionally one of these gold
        nuggets will resurface as available... no longer for a song, but at
        prices your business should be able to afford. AlphaNames.Com can help
        you locate those golden opportunities. Our domain name philosophy
        maintains that the best addresses are short, easily remembered, catchy
        and/or very subject specific. Those are the domain names we seek to
        bring to you. Indeed, over 80 percent of the domains that are submitted
        to us to include on our list are rejected because they do not meet our
        stringent criteria for a "Premier Domain." The domains that make our
        list are among the very best available upon the resale market today! No
        other domain list compares! Buy the perfect domain name here! At
        AlphaNames.Com only certified "premium" domain names are for sale...
        names that are significantly above the average in utility and value. We
        will not waste your time and ours attempting to resell domain names that
        do not fit our strict criteria for intrinsic name value. We also
        carefully restrict the sheer number of names that appear at
        AlphaNames.Com. Therefore we can present ALL of our available names for
        you to review on one web page. There are no search forms to contend
        with... no endless clicks to get to yet another list of names. Just one
        web page... the best domains available for resale on the Internet! We
        also specialize in premier "community site" domain names that are
        strongly positioned for search engine recognition, intuitive online
        discovery and easy customer recall. This Is Not The Place For
        Bargain-Hunters! Businesses are paying thousands, tens of thousands,
        hundreds of thousands... and in some cases even millions of dollars for
        domain names that they know will pay back their investment through years
        of steady service. Much like prime pieces of real estate, domain names
        represent choice locations upon the internet where clientele can more
        easily, reliably and effectively locate and remember your Internet
        address. Web addresses such as this usually don't come cheap. If you are
        looking for low-rent, cut-rate domains, this is not the place.
        Bargain-hunters, try an auction site or one of the domain sites that
        will list any and all domains submitted to them. If your business is
        currently making do with an online address that is non-specific,
        cryptic, or represents a second (or third) domain level that utilizes
        dots, dashes, slashes or squiggles to easily say or remember, then you
        need an Alpha Domain of your own! Please review the selection of
        ultimate addresses we have available now, and be thinking about what
        type of Premium Domain your business deserves... and how much your World
        Wide Web address is worth!
      </section>
    </React.Fragment>
  );
}
